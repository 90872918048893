import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "agreement" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader2 = _resolveComponent("BaseHeader2")!
  const _component_MenuEntry = _resolveComponent("MenuEntry")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseHeader2, {
      borderBottomColor: 
        _ctx.$isDarkMode() ? 'var(--grey3-color)' : 'var(--grey2-color)'
      ,
      borderBottomHeight: "1px"
    }, {
      "slot-middle": _withCtx(() => [
        _createTextVNode("이용약관")
      ]),
      _: 1
    }, 8, ["borderBottomColor"]),
    _createVNode(_component_router_link, { to: "/setting/agreement/service" }, {
      default: _withCtx(() => [
        _createVNode(_component_MenuEntry, null, {
          default: _withCtx(() => [
            _createTextVNode("(필수) 서비스 이용약관")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/setting/agreement/privacy" }, {
      default: _withCtx(() => [
        _createVNode(_component_MenuEntry, null, {
          default: _withCtx(() => [
            _createTextVNode("(필수) 개인정보 수집 및 이용")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/setting/agreement/privacy" }, {
      default: _withCtx(() => [
        _createVNode(_component_MenuEntry, null, {
          default: _withCtx(() => [
            _createTextVNode("(필수) 개인정보 제3자 제공")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/setting/agreement/privacy" }, {
      default: _withCtx(() => [
        _createVNode(_component_MenuEntry, null, {
          default: _withCtx(() => [
            _createTextVNode("(선택) 마케팅 수신 동의")
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}