
import { defineComponent } from "vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import MenuEntry from "@/components/member/MenuEntry.vue";

export default defineComponent({
  name: "Agreement",
  components: {
    BaseHeader2,
    MenuEntry,
  },
  data() {
    return {};
  },
  methods: {},
});
